// extracted by mini-css-extract-plugin
export var alignLeft = "s_qh d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qj d_bH";
export var textAlignLeft = "s_rt";
export var textAlignCenter = "s_rv";
export var textAlignRight = "s_rw";
export var embedInnerWrapperDesign1 = "s_rx d_bM";
export var embed = "s_ry d_b1";
export var titleMargin = "s_rp d_cw d_dw";
export var subtitleMargin = "s_rz d_cs d_dw";
export var paragraphMargin = "s_rB d_cw d_dw";
export var SubtitleSmall = "s_qd K_qd K_tJ K_tV";
export var SubtitleNormal = "s_qf K_qf K_tJ K_tW";
export var SubtitleLarge = "s_qg K_qg K_tJ K_tX";
export var BodySmall = "s_rC K_rC K_tJ K_t1";
export var BodyNormal = "s_rD K_rD K_tJ K_t2";
export var BodyLarge = "s_rF K_rF K_tJ K_t3";