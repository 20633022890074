// extracted by mini-css-extract-plugin
export var lbContainerOuter = "R_xg";
export var lbContainerInner = "R_xh";
export var movingForwards = "R_xj";
export var movingForwardsOther = "R_xk";
export var movingBackwards = "R_xl";
export var movingBackwardsOther = "R_xm";
export var lbImage = "R_xn";
export var lbOtherImage = "R_xp";
export var prevButton = "R_xq";
export var nextButton = "R_xr";
export var closing = "R_xs";
export var appear = "R_xt";