// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "M_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "M_fR d_fR d_bz d_bJ";
export var menuDesign6 = "M_wn d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "M_wp d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "M_wq d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "M_wr d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "M_ws d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "M_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "M_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "M_wt";
export var navbarItem = "M_nb d_bx";
export var navbarLogoItemWrapper = "M_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "M_wv d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "M_ww d_gd d_by d_Z d_bs";
export var burgerToggle = "M_wx d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "M_wy d_gd d_by d_Z d_bs";
export var burgerInput = "M_wz d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "M_wB d_f3 d_w d_H";
export var burgerLine = "M_wC d_f1";
export var burgerMenuDesign6 = "M_wD d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "M_wF d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "M_wG d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "M_wH d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "M_wJ d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "M_wK d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "M_wL d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "M_wM d_bC d_bP";
export var compact = "M_wN";
export var navDivided = "M_wP";
export var staticBurger = "M_wQ";
export var menu = "M_wR";
export var navbarDividedLogo = "M_wS";
export var nav = "M_wT";
export var fixed = "M_wV";