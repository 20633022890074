// extracted by mini-css-extract-plugin
export var alignLeft = "D_qh d_fp d_bG d_dv";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignRight = "D_qj d_fr d_bH d_dx";
export var menuContainer = "D_tf d_dW";
export var menuContainerFull = "D_tg d_dT";
export var menuMainHeader = "D_jC d_jC d_w d_c3";
export var menuComponentWrapper = "D_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "D_jH d_jH d_cy";
export var menuComponentText = "D_jD d_jD d_cn";
export var menuComponentTextDesign2 = "D_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "D_jJ d_jJ d_w d_Z";