// extracted by mini-css-extract-plugin
export var alignLeft = "N_qh d_fp d_bG d_dv";
export var alignCenter = "N_bP d_fq d_bD d_dw";
export var alignRight = "N_qj d_fr d_bH d_dx";
export var testimonialsContainer = "N_wW d_dW";
export var testimonialsContainerFull = "N_wX d_dT";
export var testimonialsMainHeader = "N_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "N_jy d_jy";
export var testimonialsComponentSmall = "N_jx d_jx";
export var testimonialsComponentsWrapper = "N_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "N_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "N_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "N_wY d_d7";
export var colEqualHeight = "N_wZ d_bz";
export var testimonialsColumnWrapper = "N_jz d_jz d_b3";
export var testimonialsImageWrapper = "N_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "N_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "N_jr d_jr d_Z d_bz";
export var design2TextWrapper = "N_w0 d_bC d_bP d_bJ";
export var design3 = "N_w1 d_cD d_H";
export var imageDesign2 = "N_w2";
export var SmallSmall = "N_qC K_qC K_tJ K_t4";
export var SmallNormal = "N_qD K_qD K_tJ K_t5";
export var SmallLarge = "N_qF K_qF K_tJ K_t2";
export var exceptionWeight = "N_sn K_t8";