// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sp d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_sq d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_sr d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_ss d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_st d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_sv d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_sw d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_sx K_sx";
export var heroExceptionNormal = "x_sy K_sy";
export var heroExceptionLarge = "x_sz K_sz";
export var Title1Small = "x_sB K_sB K_tJ K_tK";
export var Title1Normal = "x_sC K_sC K_tJ K_tL";
export var Title1Large = "x_sD K_sD K_tJ K_tM";
export var BodySmall = "x_rC K_rC K_tJ K_t1";
export var BodyNormal = "x_rD K_rD K_tJ K_t2";
export var BodyLarge = "x_rF K_rF K_tJ K_t3";