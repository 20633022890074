// extracted by mini-css-extract-plugin
export var noPadding = "P_fj d_fj";
export var videoFrameWrapper = "P_w3 d_kS d_b5";
export var videoIframeStyle = "P_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "P_w4 d_kR d_bK";
export var videoInnerWrapper = "P_w5 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "P_w6 d_dw d_w";
export var videoInnerInnerWrapper = "P_w7 d_bz d_bD d_bJ";
export var btnWrapper = "P_d2 d_w d_bz d_bD d_cj";
export var btn = "P_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n K_vh K_vb";
export var icon = "P_p9 d_cC";
export var textLeft = "P_dv";
export var textCenter = "P_dw";
export var textRight = "P_dx";