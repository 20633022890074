// extracted by mini-css-extract-plugin
export var alignLeft = "y_qh d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_qj d_fr d_bH d_dx";
export var leftWrapper = "y_qn d_bz d_bD d_bJ";
export var wrapper = "y_qm d_w d_cc";
export var imgWrapper = "y_sF";
export var caption = "y_sG";
export var text = "y_qc d_cs";
export var instagramGuttersImage = "y_g2 d_g2 d_cv d_K d_cv";
export var instagramNoGuttersImage = "y_g1 d_g1 d_cD d_K d_cD";
export var instagramContainerFull = "y_sH d_dT d_w";
export var instagramContainer = "y_sJ d_dW";
export var content = "y_sK d_cc";
export var errorContainer = "y_sL";
export var instagramImg = "y_sM d_w d_H d_dY d_bd d_0 d_8 d_7 d_4 d_9 d_bR";
export var instagramImageWrapper = "y_sN d_fg d_Z";
export var instagramBtnWrapper = "y_g9 d_g9 d_0 d_bC d_bP d_bX d_dw d_bq";
export var instagramBtnWrapperLeft = "y_sP d_d2 d_d1 d_w d_bz d_bG d_c7";
export var instagramBtnWrapperCenter = "y_sQ d_d3 d_d1 d_w d_bz d_bD d_c7";
export var instagramBtnWrapperRight = "y_sR d_d4 d_d1 d_w d_bz d_bH d_c7";