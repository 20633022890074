// extracted by mini-css-extract-plugin
export var customText = "q_qV d_dv d_cs d_cg";
export var videoIframeStyle = "q_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "q_qW d_cs d_cg d_Z";
export var customRow = "q_qb d_bD d_bf";
export var quoteWrapper = "q_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "q_pX d_H";
export var masonryImageWrapper = "q_p3";
export var title = "q_qr";
export var Title3Small = "q_qX K_qX K_tJ K_tR";
export var Title3Normal = "q_qY K_qY K_tJ K_tS";
export var Title3Large = "q_qZ K_qZ K_tJ K_tT";