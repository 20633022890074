// extracted by mini-css-extract-plugin
export var carouselContainer = "S_xv d_w d_H d_bf d_Z";
export var carouselContainerCards = "S_xw S_xv d_w d_H d_bf d_Z";
export var carouselContainerSides = "S_xx d_w d_H d_Z";
export var prevCarouselItem = "S_xy d_w d_H d_0 d_k";
export var prevCarouselItemL = "S_xz S_xy d_w d_H d_0 d_k";
export var moveInFromLeft = "S_xB";
export var prevCarouselItemR = "S_xC S_xy d_w d_H d_0 d_k";
export var moveInFromRight = "S_xD";
export var selectedCarouselItem = "S_xF d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "S_xG S_xF d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "S_xH S_xF d_w d_H d_Z d_bf";
export var nextCarouselItem = "S_xJ d_w d_H d_0 d_k";
export var nextCarouselItemL = "S_xK S_xJ d_w d_H d_0 d_k";
export var nextCarouselItemR = "S_xL S_xJ d_w d_H d_0 d_k";
export var arrowContainer = "S_xM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "S_xN S_xM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "S_xP S_xN S_xM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "S_xQ S_xM d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "S_xR";
export var nextArrowContainerHidden = "S_xS S_xQ S_xM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "S_kG d_0";
export var prevArrow = "S_xT S_kG d_0";
export var nextArrow = "S_xV S_kG d_0";
export var carouselIndicatorContainer = "S_xW d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "S_xX d_w d_bz d_bF";
export var carouselText = "S_xY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "S_xZ S_xY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "S_x0 d_b7";
export var carouselIndicator = "S_x1 S_x0 d_b7";
export var carouselIndicatorSelected = "S_x2 S_x0 d_b7";
export var arrowsContainerTopRight = "S_x3 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "S_x4 d_0 d_bl d_bC";
export var arrowsContainerSides = "S_x5 d_0 d_bl d_bC";
export var smallArrowsBase = "S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "S_x7 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "S_x8 S_x7 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "S_x9 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "S_yb S_x9 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "S_p9";
export var iconSmall = "S_yc";
export var multipleWrapper = "S_yd d_bC d_bF d_bf";
export var multipleImage = "S_yf d_bC";
export var sidesPrevContainer = "S_yg S_x7 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "S_yh S_x7 S_x6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";