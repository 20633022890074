// extracted by mini-css-extract-plugin
export var socialContainer = "F_th";
export var instagramLink = "F_tj";
export var socialWrapperLeft = "F_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "F_tk";
export var socialContentContainerFull = "F_tl";
export var instagramContainer = "F_sJ";
export var twitterContainer = "F_tm";
export var facebookContainer = "F_tn";
export var socialErrorContainer = "F_tp";
export var facebookContainerWide = "F_tq";
export var twitterContainerWide = "F_tr";
export var socialParagraphCenter = "F_ts";
export var instaWrapper = "F_tt";
export var SubtitleSmall = "F_qd K_qd K_tJ K_tV";
export var SubtitleNormal = "F_qf K_qf K_tJ K_tW";
export var SubtitleLarge = "F_qg K_qg K_tJ K_tX";
export var textLeft = "F_dv";
export var textCenter = "F_dw";
export var textRight = "F_dx";