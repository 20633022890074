// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_tv d_bD";
export var storyRowWrapper = "G_hx d_hx d_bK";
export var storyLeftWrapper = "G_tw d_bz d_bP";
export var storyWrapperFull = "G_tx d_cD";
export var storyWrapperFullLeft = "G_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "G_mv d_hy";
export var storyLeftWrapperCenter = "G_ty d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "G_tz d_hF";
export var storyHeader = "G_tB d_hD d_w d_cs";
export var storyHeaderCenter = "G_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "G_hB d_hB d_by d_dw";
export var storyBtnWrapper = "G_tC d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "G_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "G_qB d_fg d_Z";
export var imageWrapperFull = "G_rm d_w d_H d_bf d_Z";
export var SubtitleSmall = "G_qd K_qd K_tJ K_tV";
export var SubtitleNormal = "G_qf K_qf K_tJ K_tW";
export var SubtitleLarge = "G_qg K_qg K_tJ K_tX";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";